import request from "./http"


/**
 * @description 获取门店名称、技师名称、用户名称下拉框列表
 * @return 获取门店名称、技师名称、用户名称下拉框列表
 */
 export function getRecordQueryCondition() {
    return request({
        url: `/api/user/store/getRecordQueryCondition?`,
        method: "post",
    })
}


/**
 * @description 获取录音数据
 *  @param  {String}  多少页
 *  @param  {String} page 第几页
 *  @param  {Object}}data 参数
 * @return 获取录音数据
 */
export function getRecordList(limit, page,data) {
    return request({
        url: `/api/user/store/getRecordList?limit=${limit}&page=${page}`,
        method: "post",
        data
    })
}


/**
 * @description 删除录音数据
 *  @param  {String} id id
 * @return 删除录音数据
 */
 export function delRecordById(id) {
    return request({
        url: `/api/user/store/deleteRecordById/${id}`,
        method: "get",
    })
}