<template>
  <div class="tape-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      :isAdd="false"
      :isExport="false"
      @_query="_query"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      :isButtons="false"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
    >
      <el-table-column
        slot="tapeFile"
        label="录音文件(点击录音右侧三个点即可下载录音文件)"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <audio
            style="width: 100%; height: 50px"
            :ref="'audio' + scope.$index"
            :src="baseURL + scope.row.recordUrl"
            :autoplay="false"
            :controls="playIndex === scope.$index"
          />
          <el-button
            class="el-icon-caret-right"
            type="text"
            v-if="playIndex !== scope.$index"
            @click="toReadAudio(scope.$index)"
          >
            播放
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        slot="operate"
        label="操作"
        align="center"
        fixed="right"
        width="100px"
      >
        <template slot-scope="scope">
          <!-- <el-button size="small" type="primary" @click="down(scope.row)"
            >下载</el-button
          > -->
          <el-button size="small" type="danger" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </CommonTable>
  </div>
</template>

<script>
import { getRecordQueryCondition, getRecordList } from "../../api/tape-manage"

import http from "../../api/http"

export default {
  name: "TapeManage",
  data() {
    return {
      // 查询表单
      queryForm: {
        storeId: "",
        massagistId: "",
        userId: "",
      },

      // 查询表单项
      queryFormItemList: [
        {
          type: "select",
          prop: "storeId",
          clearable: true,
          placeholder: "请输入要查询门店名称",
          options: [],
        },
        {
          type: "select",
          prop: "massagistId",
          clearable: true,
          placeholder: "请输入要查询技师名称",
          options: [],
        },
        {
          type: "select",
          prop: "userId",
          clearable: true,
          placeholder: "请输入要查询用户名称",
          options: [],
        },
      ],

      // 加载
      loading: false,

      // 表格数据
      tableData: [],

      // 表头列
      columns: [
        { label: "录音创建时间", prop: "createTime", width: 166 },
        { label: "门店名称", prop: "storeName" },
        { label: "技师名称", prop: "massagistName", width: 166 },
        { label: "用户名称", prop: "userName", width: 166 },
        { slot: "tapeFile" },
        { slot: "operate" },
      ],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      baseURL: "",

      playIndex: 0,
    }
  },

  mounted() {
    this.baseURL = ""
    // this.baseURL = http.defaults.baseURL

    this.getData()
  },

  methods: {
    // 查询
    _query() {
      this.getData()
    },

    // 下载
    down(row) {
      console.log("下载", row)
    },

    // 删除,
    del(row) {
      console.log("删除", row)
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 获取门店名称、技师名称、用户名称下拉框列表
    async getSelect() {
      try {
        const res = await getRecordQueryCondition()
        console.log("获取门店名称、技师名称、用户名称下拉框列表", res)
        this.queryFormItemList[0].options = res.data.storeList
        this.queryFormItemList[1].options = res.data.massagistList
        this.queryFormItemList[2].options = res.data.userList
      } catch (error) {
        this.$message({
          showClose: true,
          message: "获取可查询值失败",
          type: "error",
        })
      }
    },

    // 获取录音数据
    async getData() {
      //  this.getSelect()
      this.loading = true
      const { limit, page } = this.pagingOptions
      try {
        const res = await getRecordList(limit, page, this.queryForm)
        console.log("获取录音数据", res)
        this.loading = false
        this.tableData = res.data.list
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    // 当前录音播放---
    toReadAudio(index) {
      let length = this.tableData.length || 0 // this.tableData.length是总共有几条音频数据
      if (length) {
        for (let i = 0; i < length; i++) {
          let audioOne = "audio" + i
          audioOne = this.$refs[audioOne]
          if (i === index) {
            if (audioOne.paused) {
              // 切换播放中的css样式
              this.playIndex = index
              // audioOne.load() //音频重新加载，重新播放
              audioOne.play()
            } else {
              audioOne.pause()
              this.playIndex = null
            }
          } else {
            audioOne.pause()
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tape-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>